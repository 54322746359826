import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import "./../../styles/products.css"

//import components
import GetStartedBanner from "./../../components/get-started-footer"
import GlobalCoverageSection from "./../../components/global-coverage-section/global-coverage"
import MostPopularIntegrations from "./../../components/most-popular-integration-section/most-popular-integrations"
import TrustedByCompanies from "./../../components/companies-trusted"

const HeadingSection = ({ data }) => {
  const [productName, setproductName] = useState("SSL Monitoring")
  const [title, settitle] = useState(
    "Keep your website secure with SSL certificate monitoring"
  )
  const [description, setdescription] = useState(
    "We monitor the expiration date and notify you when it's time to update your certificate."
  )
  const [getStartedButton, setgetStartedButton] = useState({
    name: "Get started for free",
    link: "https://app.odown.io/signup",
  })

  return (
    <div className="u-features ssl-monitoring">
      <div className="container">
        <div className="hero-col hero--text">
          <div className="hero-text__wrapper">
            <h1 className="u-features__heading-title">{title}</h1>
            <p className="u-features__heading-desc">{description}</p>
            <div className="u-features__heading-btns">
              <OutboundLink
                className="btn btn-primary"
                eventCategory="Launch the Odown App"
                eventAction="Click"
                eventLabel="Goto signup"
                href={getStartedButton.link}
                target="_blanc"
              >
                {getStartedButton.name}
              </OutboundLink>
            </div>
          </div>
        </div>
        <div className="hero-col hero--image">
          <div className="image-cover">
            <div className="image-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.headerImage.childImageSharp.fluid}
                alt={productName}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const KeyToCustomerTrustSection = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">ssl monitoring</span>
            <h2 className="u-main-feature_tc-title">
              The primary key to customer trust
            </h2>
            {/* <p className="u-main-feature_tc-desc">The standard duration of an SSL certificate can extend well beyond one year, and some certificates may have shorter durations. That's why we've implemented SSL monitoring, so you don't have to remember when it expires.</p> */}
            <p className="u-main-feature_tc-desc">
              It would be best if you renewed your SSL certificates on the web
              every so often to ensure data security. If a certificate expires,
              then the information encrypted is exposed, which puts your
              personal information, passwords, and financial data at risk.
              That's why we've got SSL certificate monitoring in place, so you
              don't have to remember when it's due to expire.
            </p>
            <p className="u-main-feature_tc-desc small">
              Using an automated system, we can ensure your site's security
              isn't compromised. This advanced feature keeps you from checking
              your certificate constantly, allowing you to focus on your
              business.
            </p>
            <p className="u-main-feature_tc-desc small">
              Rest easy knowing that we'll notify you well in advance when your
              SSL certificate is up for renewal.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.FeatureTrustCustomer.childImageSharp.fluid}
                alt="SSL Certificate Monitoring - The main key to customer trust"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const GetNotifiedImmediatelySection = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">Instant alerts</span>
            <h2 className="u-main-feature_tc-title">
              Get notified immediately
            </h2>
            {/* <p className="u-main-feature_tc-desc">No more SSL certificate problems. We will alert you before your certificate expires. Set an alert for custom days before expiration.</p> */}
            <p className="u-main-feature_tc-desc">
              You know what it’s like. You wake up one morning and realize that
              your SSL certificate has expired. No one wants to be the last to
              find out. That’s why we decided to do something about it.{" "}
            </p>
            <p className="u-main-feature_tc-desc">
              With our SSL certificate monitoring solution, you can keep track
              of your SSL certificate expiration date. Set an alert for 1, 14,
              30 days, or even a custom day before the expiry date, so you can
              ensure that your website stays online and works properly at all
              times.
            </p>
            <p className="u-main-feature_tc-desc small">
              And all SSL certificate expiration notifications are available
              through multiple alert channels like Email, Slack, Webhooks, SMS,
              Telegrams, and Discord.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.FeatureNotify.childImageSharp.fluid}
                alt="SSL Certificate Monitoring - Get notified immediately"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const SimpleSetupSection = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">
              SSL verification process
            </span>
            <h2 className="u-main-feature_tc-title">Easy to setup</h2>
            <p className="u-main-feature_tc-desc">
              Setup is dead simple; add the URL you want to monitor. Our SSL
              certificate monitoring service will check your SSL certificate
              every day. We'll let you know via a simple notification if
              something's wrong.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.EasyToSetup.childImageSharp.fluid}
                alt="SSL Certificate Monitoring - Easy to setup"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const SSL_Monitoring = ({ data }) => (
  <Layout
    currentPage={`product-ssl_monitoring`}
    navParams={{ textColor: "black" }}
  >
    <SEO
      title="Trust Odown for Reliable SSL Certificate Monitoring"
      description="Avoid costly SSL certificate lapses with Odown's automated monitoring. Get notified when it's time to renew and keep your website secure."
      pathname={`/product/ssl-monitoring`}
      // meta={[
      // 	{
      // 		name : `twitter:image:src`,
      // 		content :  data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
      // 	},
      // 	{
      // 		name : `og:image`,
      // 		content : data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
      // 	},

      // ]}
      image={{
        src: "/general-image-og--ssl-monitoring.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <HeadingSection data={data} />
    <TrustedByCompanies direction="left" />
    <KeyToCustomerTrustSection data={data} />
    <GetNotifiedImmediatelySection data={data} />
    <SimpleSetupSection data={data} />
    <MostPopularIntegrations data={data} />
    <GlobalCoverageSection data={data} />
    <GetStartedBanner />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }

    headerImage: file(
      relativePath: { eq: "product__ssl-monitoring__header.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    FeatureTrustCustomer: file(
      relativePath: {
        eq: "product__ssl-monitoring__option__trust-customer.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    FeatureNotify: file(
      relativePath: { eq: "product__ssl-monitoring__option__notify.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    EasyToSetup: file(
      relativePath: { eq: "product__ssl-monitoring__option__easy-to-setup.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
export default SSL_Monitoring
